export default {
  lazy: 'js-lazy',
  parallax: 'js-parallax',
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
  },
  slider: {
    wrap: 'js-slider',
    prev: 'js-slider-prev',
    next: 'js-slider-next',
  },
  scrollTo: {
    next: 'js-scroll-to-next',
  },
}
