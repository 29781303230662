import classNames from '../classNames'

export default async () => {
  const parallaxEl = document.querySelector(`.${classNames.parallax}`)
  if (!parallaxEl) return
  const shouldInit =
    (window.matchMedia('(min-width: 1200px)').matches &&
      !window.matchMedia('(min-height: 63vw)').matches) ||
    !window.matchMedia('(min-height: 63vw)').matches

  if (!shouldInit) return

  const { default: Rellax } = await import(/* webpackChunkName: "rellax" */ 'rellax')
  const rellax = new Rellax(`.${classNames.parallax}`)
}
