import classNames from '../classNames'
import { isModernBrowser, isSafari } from '../helpers'

export default () => {
  const init = async () => {
    const btn = document.querySelector(`.${classNames.scrollTo.next}`)
    if (!btn) return

    if (isModernBrowser && isSafari) {
      const { default: smoothscroll } = await import(
        /* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill'
      )
      smoothscroll.polyfill()
    }
  }

  const onClick = ({ target }) => {
    const btnToNext = target.closest(`.${classNames.scrollTo.next}`)

    const scrollToNext = () => {
      const nextSection = btnToNext.closest('section')?.nextElementSibling
      if (!nextSection) return

      const { top } = nextSection.getBoundingClientRect()

      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    }

    if (btnToNext) scrollToNext()
  }

  return {
    init,
    onClick,
  }
}
