import classNames from '../classNames'

export default async () => {
  const sliders = [...document.querySelectorAll(`.${classNames.slider.wrap}`)]
  if (!sliders.length) return

  const { tns } = await import(/* webpackChunkName: "tiny-slider" */ 'tiny-slider/src/tiny-slider')

  sliders.forEach(slider => {
    const wrap = slider.closest('.slider__wrap')
    const prevButton = wrap.querySelector(`.${classNames.slider.prev}`)
    const nextButton = wrap.querySelector(`.${classNames.slider.next}`)

    tns({
      prevButton,
      nextButton,
      container: slider,
      items: 1,
      loop: false,
      nav: false,
      gutter: 10,
    })
  })
}
